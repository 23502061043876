
    <template>
      <section class="content element-doc">
        <h2>Tag 标签</h2>
<p>用于标记和选择。</p>
<h3>基础用法</h3>
<demo-block>
        <div><p>由<code>type</code>属性来选择 tag 的类型，也可以通过<code>color</code>属性来自定义背景色。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tag&gt;标签一&lt;/el-tag&gt;
&lt;el-tag type=&quot;success&quot;&gt;标签二&lt;/el-tag&gt;
&lt;el-tag type=&quot;info&quot;&gt;标签三&lt;/el-tag&gt;
&lt;el-tag type=&quot;warning&quot;&gt;标签四&lt;/el-tag&gt;
&lt;el-tag type=&quot;danger&quot;&gt;标签五&lt;/el-tag&gt;
</code></pre></template></demo-block><h3>可移除标签</h3>
<demo-block>
        <div><p>设置<code>closable</code>属性可以定义一个标签是否可移除。默认的标签移除时会附带渐变动画，如果不想使用，可以设置<code>disable-transitions</code>属性，它接受一个<code>Boolean</code>，true 为关闭。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tag v-for=&quot;tag in tags&quot; :key=&quot;tag.name&quot; closable :type=&quot;tag.type&quot;&gt;
  {{tag.name}}
&lt;/el-tag&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const state = reactive({
        tags: [
          { name: '标签一', type: '' },
          { name: '标签二', type: 'success' },
          { name: '标签三', type: 'info' },
          { name: '标签四', type: 'warning' },
          { name: '标签五', type: 'danger' }
        ]
      })
      return { ...toRefs(state) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>动态编辑标签</h3>
<p>动态编辑标签可以通过点击标签关闭按钮后触发的 <code>close</code> 事件来实现</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tag
  :key=&quot;tag&quot;
  v-for=&quot;tag in dynamicTags&quot;
  closable
  :disable-transitions=&quot;false&quot;
  @close=&quot;handleClose(tag)&quot;
&gt;
  {{tag}}
&lt;/el-tag&gt;
&lt;el-input
  class=&quot;input-new-tag&quot;
  v-if=&quot;inputVisible&quot;
  v-model=&quot;inputValue&quot;
  ref=&quot;saveTagInput&quot;
  size=&quot;small&quot;
  @keyup.enter.native=&quot;handleInputConfirm&quot;
  @blur=&quot;handleInputConfirm&quot;
&gt;
&lt;/el-input&gt;
&lt;el-button v-else class=&quot;button-new-tag&quot; size=&quot;small&quot; @click=&quot;showInput&quot;
  &gt;+ New Tag&lt;/el-button
&gt;

&lt;style&gt;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
&lt;/style&gt;

&lt;script&gt;
  import { reactive, ref, toRefs, nextTick } from 'vue'
  export default {
    setup() {
      const state = reactive({
        dynamicTags: ['标签一', '标签二', '标签三'],
        inputVisible: false,
        inputValue: ''
      })
      const saveTagInput = ref(null)
      const handleClose = (tag) =&gt; {
        // state.dynamicTags.splice(state.dynamicTags.indexOf(tag), 1)
      }
      const showInput = () =&gt; {
        state.inputVisible = true
        nextTick((_) =&gt; {
          saveTagInput.value.input.focus()
        })
      }

      const handleInputConfirm = () =&gt; {
        let inputValue = state.inputValue
        if (inputValue) {
          state.dynamicTags.push(inputValue)
        }
        state.inputVisible = false
        state.inputValue = ''
      }

      return {
        ...toRefs(state),
        handleClose,
        showInput,
        handleInputConfirm,
        saveTagInput
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>不同尺寸</h3>
<p>Tag 组件提供除了默认值以外的三种尺寸，可以在不同场景下选择合适的按钮尺寸。</p>
<demo-block>
        <div><p>额外的尺寸：<code>medium</code>、<code>small</code>、<code>mini</code>，通过设置<code>size</code>属性来配置它们。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tag closable&gt;默认标签&lt;/el-tag&gt;
&lt;el-tag size=&quot;medium&quot; closable&gt;中等标签&lt;/el-tag&gt;
&lt;el-tag size=&quot;small&quot; closable&gt;小型标签&lt;/el-tag&gt;
&lt;el-tag size=&quot;mini&quot; closable&gt;超小标签&lt;/el-tag&gt;
</code></pre></template></demo-block><h3>不同主题</h3>
<p>Tag 组件提供了三个不同的主题：<code>dark</code>、<code>light</code> 和 <code>plain</code></p>
<demo-block>
        <div><p>通过设置<code>effect</code>属性来改变主题，默认为 <code>light</code></p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div class=&quot;tag-group&quot;&gt;
  &lt;span class=&quot;tag-group__title&quot;&gt;Dark&lt;/span&gt;
  &lt;el-tag
    v-for=&quot;item in items&quot;
    :key=&quot;item.label&quot;
    :type=&quot;item.type&quot;
    effect=&quot;dark&quot;
  &gt;
    {{ item.label }}
  &lt;/el-tag&gt;
&lt;/div&gt;
&lt;div class=&quot;tag-group&quot;&gt;
  &lt;span class=&quot;tag-group__title&quot;&gt;Plain&lt;/span&gt;
  &lt;el-tag
    v-for=&quot;item in items&quot;
    :key=&quot;item.label&quot;
    :type=&quot;item.type&quot;
    effect=&quot;plain&quot;
  &gt;
    {{ item.label }}
  &lt;/el-tag&gt;
&lt;/div&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const state = reactive({
        items: [
          { type: '', label: '标签一' },
          { type: 'success', label: '标签二' },
          { type: 'info', label: '标签三' },
          { type: 'danger', label: '标签四' },
          { type: 'warning', label: '标签五' }
        ]
      })
      return { ...toRefs(state) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>type</td>
<td>类型</td>
<td>string</td>
<td>success/info/warning/danger</td>
<td>—</td>
</tr>
<tr>
<td>closable</td>
<td>是否可关闭</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>disable-transitions</td>
<td>是否禁用渐变动画</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>hit</td>
<td>是否有边框描边</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>color</td>
<td>背景色</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>size</td>
<td>尺寸</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>effect</td>
<td>主题</td>
<td>string</td>
<td>dark / light / plain</td>
<td>light</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>click</td>
<td>点击 Tag 时触发的事件</td>
<td>—</td>
</tr>
<tr>
<td>close</td>
<td>关闭 Tag 时触发的事件</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tag = _resolveComponent("el-tag")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_tag, null, {
      default: _withCtx(() => [
        _createTextVNode("标签一")
      ]),
      _: 1
    }),
    _createVNode(_component_el_tag, { type: "success" }, {
      default: _withCtx(() => [
        _createTextVNode("标签二")
      ]),
      _: 1
    }),
    _createVNode(_component_el_tag, { type: "info" }, {
      default: _withCtx(() => [
        _createTextVNode("标签三")
      ]),
      _: 1
    }),
    _createVNode(_component_el_tag, { type: "warning" }, {
      default: _withCtx(() => [
        _createTextVNode("标签四")
      ]),
      _: 1
    }),
    _createVNode(_component_el_tag, { type: "danger" }, {
      default: _withCtx(() => [
        _createTextVNode("标签五")
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode } = Vue

function render (_ctx, _cache) {
  const _component_el_tag = _resolveComponent("el-tag")

  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
    return (_openBlock(), _createBlock(_component_el_tag, {
      key: tag.name,
      closable: "",
      type: tag.type
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(tag.name), 1)
      ]),
      _: 2
    }, 1032, ["type"]))
  }), 128))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const state = reactive({
        tags: [
          { name: '标签一', type: '' },
          { name: '标签二', type: 'success' },
          { name: '标签三', type: 'info' },
          { name: '标签四', type: 'warning' },
          { name: '标签五', type: 'danger' }
        ]
      })
      return { ...toRefs(state) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, withKeys: _withKeys, createCommentVNode: _createCommentVNode } = Vue

function render (_ctx, _cache) {
  const _component_el_tag = _resolveComponent("el-tag")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dynamicTags, (tag) => {
      return (_openBlock(), _createBlock(_component_el_tag, {
        key: tag,
        closable: "",
        "disable-transitions": false,
        onClose: $event => (_ctx.handleClose(tag))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(tag), 1)
        ]),
        _: 2
      }, 1032, ["onClose"]))
    }), 128)),
    (_ctx.inputVisible)
      ? (_openBlock(), _createBlock(_component_el_input, {
          key: 0,
          class: "input-new-tag",
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": $event => (_ctx.inputValue = $event),
          ref: "saveTagInput",
          size: "small",
          onKeyup: _withKeys(_ctx.handleInputConfirm, ["enter","native"]),
          onBlur: _ctx.handleInputConfirm
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup", "onBlur"]))
      : (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          class: "button-new-tag",
          size: "small",
          onClick: _ctx.showInput
        }, {
          default: _withCtx(() => [
            _createTextVNode("+ New Tag")
          ]),
          _: 1
        }, 8, ["onClick"]))
  ], 64))
}
  
    
        const { reactive, ref, toRefs, nextTick } = Vue
        
  const democomponentExport = {
    setup() {
      const state = reactive({
        dynamicTags: ['标签一', '标签二', '标签三'],
        inputVisible: false,
        inputValue: ''
      })
      const saveTagInput = ref(null)
      const handleClose = (tag) => {
        // state.dynamicTags.splice(state.dynamicTags.indexOf(tag), 1)
      }
      const showInput = () => {
        state.inputVisible = true
        nextTick((_) => {
          saveTagInput.value.input.focus()
        })
      }

      const handleInputConfirm = () => {
        let inputValue = state.inputValue
        if (inputValue) {
          state.dynamicTags.push(inputValue)
        }
        state.inputVisible = false
        state.inputValue = ''
      }

      return {
        ...toRefs(state),
        handleClose,
        showInput,
        handleInputConfirm,
        saveTagInput
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tag = _resolveComponent("el-tag")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_tag, { closable: "" }, {
      default: _withCtx(() => [
        _createTextVNode("默认标签")
      ]),
      _: 1
    }),
    _createVNode(_component_el_tag, {
      size: "medium",
      closable: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("中等标签")
      ]),
      _: 1
    }),
    _createVNode(_component_el_tag, {
      size: "small",
      closable: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("小型标签")
      ]),
      _: 1
    }),
    _createVNode(_component_el_tag, {
      size: "mini",
      closable: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("超小标签")
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { createVNode: _createVNode, renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx } = Vue

function render (_ctx, _cache) {
  const _component_el_tag = _resolveComponent("el-tag")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", { class: "tag-group" }, [
      _createVNode("span", { class: "tag-group__title" }, "Dark"),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_el_tag, {
          key: item.label,
          type: item.type,
          effect: "dark"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label), 1)
          ]),
          _: 2
        }, 1032, ["type"]))
      }), 128))
    ]),
    _createVNode("div", { class: "tag-group" }, [
      _createVNode("span", { class: "tag-group__title" }, "Plain"),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_el_tag, {
          key: item.label,
          type: item.type,
          effect: "plain"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label), 1)
          ]),
          _: 2
        }, 1032, ["type"]))
      }), 128))
    ])
  ], 64))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const state = reactive({
        items: [
          { type: '', label: '标签一' },
          { type: 'success', label: '标签二' },
          { type: 'info', label: '标签三' },
          { type: 'danger', label: '标签四' },
          { type: 'warning', label: '标签五' }
        ]
      })
      return { ...toRefs(state) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  